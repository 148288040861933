
import "./style.css";
import { useEffect, useRef } from 'react';
import { useInView } from "react-intersection-observer";
import emailjs from '@emailjs/browser';
//import env from "react-dotenv";

export default function Contact() {
 
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault(); 
    emailjs
      .sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, {
        publicKey: process.env.REACT_APP_YOUR_PUBLIC_KEY,
      })
      .then(
        () => {
          alert("Seu email foi enviado!")
        },
        (error) => {
          alert("Ocorreu um erro, tente novamente mais tarde.")
          console.log(error)

        },
      ); 
  };

  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: false,
    rootMargin: '0px 0px',
    threshold: 0.9,
  })
  
  useEffect(() => {
    if (inView) window.location.replace('#Contact')

  }, [inView])


  return (
    <div ref={ref} id="Contact">
      <h1 className="title">Contato</h1>
      <div className="contact-data">
        <p className="phone">Telefone: (71) 98857-0322</p>
        <p className="email">Email: <a href="mailto:mssaback@yahoo.com.br">mssaback@yahoo.com.br</a></p>
        <p className="other"><a href="https://linktr.ee/mssaback">Outros contatos</a></p>

        <div className="email-sending-container">
        <h2 className="send-email">Envie um email para contato!</h2>
        <form ref={form} onSubmit={sendEmail} className="email-sending-box">
          <label for="nome" className="label">Seu Nome:</label>
          <input className="input name" type="text" name="user_name" />
          <label for="email" className="label">Seu Email de contato:</label>
          <input className="input email" type="email" name="user_email" />
          <label for="message" className="label">Sua mensagem:</label>
          <textarea className="input message" type="text" name="message" />
          <input type="submit" className="button" value="Enviar" />
        </form>
        </div>
      </div>
    </div>
  )
}
