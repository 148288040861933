import "./style.css"
import { useEffect } from 'react';
import { useInView } from "react-intersection-observer";

export default function AboutMe() {
 
  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: false,
    rootMargin: '0px 0px',
    threshold: 0.9,
  })
  
  useEffect(() => {
    if (inView) window.location.replace('#AboutMe')

  }, [inView])
  return (
    <div ref={ref} id="AboutMe">
      <h1 className="title">Sobre o Autor</h1>
      <div className="content">
          <p className="text">Músico, pedagogo e mestre em música, atua na educação básica com foco na educação musical 
            e na construção de uma matriz genealógica afro-centrada. Pesquisador da relação entre música e desenvolvimento 
            cognitivo com ênfase na construção do conhecimento lógico matemático a partir dos postulados piagetianos. 
            Atua na área de educação musical, currículo de música na educação básica, iniciação musical e música percussiva 
            da cultura malinkê. Trabalha também com formação de professores e tecnologia na educação.</p>
          <div className="image-container">
            <img src="/images/author.jpg" alt="Imagem do autor" className="image"/>
            <a href="https://www.instagram.com/nepecs_educ/">
              <img src="/images/instagram.png" alt="Imagem do autor" className="instagram"/>
            </a>
          </div>
      </div>

      <p className="text"></p>
    </div>
  )
}
