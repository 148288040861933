import Navbar from './Navbar';
import { BrowserRouter } from 'react-router-dom';
import Landing from './Landing';
import Synopse from './Synopse';
import Overview from './Overview';
import AboutMe from './AboutMe';
import Buy from './Buy';
import Contact from './Contact';

import './App.css';

function App() {
  return (
    <div classname="App">
      <BrowserRouter>
      <Navbar />
      <Landing />
      <Synopse />
      <Overview />
      <AboutMe />
      <Buy />
      <Contact />
      </BrowserRouter>
    </div>
  );
}

export default App;
