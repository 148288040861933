import "./style.css"
import { useEffect } from 'react';
import { useInView } from "react-intersection-observer";

export default function Synopse() {
  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: false,
    rootMargin: '0px 0px',
    threshold: 0.9,
  })
  
  useEffect(() => {
    if (inView) window.location.replace('#Synopse');
  }, [inView])

  return (
    <div ref={ref} id="Synopse">
      <h1 className="title">Sinopse</h1>
      <div className="content">
        <p className="text">A construção do conhecimento musical tem alcançado
        um interesse cada vez maior enquanto campo de pesquisa da psicologia e 
        da pedagogia musical. O foco dado à cognição, por parte de um número 
        crescente de pesquisadores, tem ampliado as discussões no campo da
        psicologia da música e da pedagogia musical. Para esta primeira
        área, uma dessas colaborações tem sido a possibilidade de se 
        organizar as práticas pedagógicas musicais e o currículo de música a 
        partir do entendimento desses processos mentais. <br /> Esses exemplos
        levam-nos a pensar que tanto a psicologia da música quanto a pedagogia
        musical devem trabalhar de forma conjunta, num processo interdisciplinar,
        para alcançar maiores entendimentos e ampliações de possibilidades no que 
        diz respeito à construção do conhecimento musical.</p>
        <img src="/images/presentsbook.jpg" alt="Autor apresenta o livro" className="image"/>
      </div>
    </div>
  )
}
