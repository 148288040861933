import "./style.css"
import { useState } from "react"
import { HashLink } from 'react-router-hash-link';
// import { useLocation } from 'react-router-dom';

function useLocationHash() {
    const [hash, setHash] = useState(window.location.hash);
    window.addEventListener("hashchange", () => setHash(window.location.hash));
    return hash;
}


export default function Navbar() {
  // let location = useLocation();
  const initializeStates = ["", "", "", "", "", ""]
  const [buttonStates, setButtonStates] = useState(initializeStates.slice());
  const hash = useLocationHash();
    function animation(index, hashName) {
    window.location.replace(hashName)
    let newStates = initializeStates.slice()
    newStates[index] = "active animation"
    setButtonStates(newStates)
    console.log(buttonStates)
    setTimeout(() => {
      const newActive = initializeStates.slice();
      // newActive[index] = "active"
      setButtonStates(newActive)
      console.log(buttonStates)
    }, 100)
  }

  return (
    <div className="Navbar">
      <HashLink className={ 
      `${buttonStates[0]} ${hash === '#Landing' ? " active" : ""} linkInstance` } 
      to="/#Landing" onClick={() => animation(0, '#Landing')}>Home</HashLink>
      <HashLink className={ 
      `${buttonStates[1]} ${hash === '#Synopse' ? " active" : ""} linkInstance` } 
      to="/#Synopse" 
      onClick={() => animation(1, '#Synopse')}>Sinopse</HashLink>
      <HashLink className={ 
      `${buttonStates[2]} ${hash === '#Overview' ? " active" : ""} linkInstance` }
      to="/#Overview" onClick={() => animation(2, '#Overview')}>O Livro</HashLink>
      <HashLink className={ 
      `${buttonStates[3]} ${hash === '#AboutMe' ? " active" : ""} linkInstance` }
      to="/#AboutMe" onClick={() => animation(3, "#AboutMe")}>O Autor</HashLink>
      <HashLink className={ 
      `${buttonStates[4]} ${hash === '#Buy' ? " active" : ""} linkInstance` }
      to="/#Buy" onClick={() => animation(4, "#Buy")}>Formatos</HashLink>
      <HashLink className={ 
      `${buttonStates[5]} ${hash === '#Contact' ? " active" : ""} linkInstance` }
      to="/#Contact" onClick={() => animation(5, "#Contact")}>Contato</HashLink>

    </div>
  )
}
